import "./PrimaryBtn.scss";

const PrimaryBtn = ({
  title,
  type,
  clickHandler,
  theme,
  isDesabled,
  classList,
  style,
  icon
}) => {
  return (
    <button
      type={type ? type : "button"}
      className={`square_btn ${theme} ${classList}`}
      onClick={clickHandler}
      disabled={isDesabled}
      style={style}
    >
      {icon ? icon : ""}
      {title}
      <span className="a"></span>
      <span className="b"></span>
      <span className="c"></span>
      <span className="d"></span>
    </button>
  );
};

export default PrimaryBtn;
