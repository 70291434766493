import { useState } from "react";
import AuthLayout from "../../authPages/AuthLayout";
import "./MachineOverview.scss";
import MachineFaq from "./machineFaq/MachineFaq";
import HowToPlayModal from "./howtoPlayModal/HowToPlayModal";
import OverView from "./overview/OverView";

const MachineOverview = () => {
  const [tab, setTab] = useState("a");
  const [modalShow, setModalShow] = useState(false);

  return (
    <AuthLayout isLabs={true}>
      <div className="overview_landing">
        <div className="overlay"></div>
        <div className="overview_top_nav">
          <h6>Seasonal 4</h6>
          <div className="tabs_line">
            <button
              className={tab === "a" ? "active" : ""}
              type="button"
              onClick={() => setTab("a")}
            >
              Overview
            </button>
            <button
              className={tab === "b" ? "active" : ""}
              type="button"
              onClick={() => setTab("b")}
            >
              FAQ
            </button>
            <button type="button" onClick={() => setModalShow(true)}>
              How To Play
            </button>
          </div>
          <div className="notice">
            <p>
              SEASON ENDS IN <span>8 DAYS</span>
            </p>
          </div>
        </div>
      </div>

      {tab === "a" && <OverView />}

      {tab === "b" && <MachineFaq />}
      <HowToPlayModal show={modalShow} onHide={() => setModalShow(false)} />
    </AuthLayout>
  );
};

export default MachineOverview;
