import "./TabDivider.scss";

const TabDivider = ({title, side}) => {
    return(
        <div className="tab_divider">
          <p style={{opacity:`${side ? 1 : 0}`}} >{title}</p>
        </div>
    )
}

export default TabDivider;