import "./NotificationCard.scss";
import { icon } from "../../../../utils/images/icons";
import { Fragment } from "react";

const NotificationCard = ({ status, notification }) => {
  const dateFormate = (dateString) => {
    const inputDate = new Date(dateString);

    // Format the date to the desired output format
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const outputDateStr = inputDate.toLocaleString("en-US", options);

    return outputDateStr;
  };

  return (
    <div className={`notification_card ${status ? "open" : "close"}`}>
      <h6>Notifications</h6>
      {notification?.length > 0 ? (
        notification.map((data) => {
          return (
            <Fragment key={data._id}>
              <div className="notification_data">            
                  <h6 className="date">                   
                    {dateFormate(data?.updatedAt)}
                  </h6>
                  <div
                    className="notification"
                    dangerouslySetInnerHTML={{ __html: data?.name }}
                  ></div>
            
              </div>
            </Fragment>
          );
        })
      ) : (
        <p>Notification not found</p>
      )}
    </div>
  );
};

export default NotificationCard;
