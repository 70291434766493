export const skillList = [
    { "id": 1, "skill": "Leadership" },
    { "id": 2, "skill": "Communication" },
    { "id": 3, "skill": "Project Management" },
    { "id": 4, "skill": "Time Management" },
    { "id": 5, "skill": "Teamwork" },
    { "id": 6, "skill": "Problem Solving" },
    { "id": 7, "skill": "Critical Thinking" },
    { "id": 8, "skill": "Creativity" },
    { "id": 9, "skill": "Adaptability" },
    { "id": 10, "skill": "Decision Making" },
    { "id": 11, "skill": "Data Analysis" },
    { "id": 12, "skill": "Research" },
    { "id": 13, "skill": "Strategic Planning" },
    { "id": 14, "skill": "Microsoft Office Suite" },
    { "id": 15, "skill": "Customer Service" },
    { "id": 16, "skill": "Sales" },
    { "id": 17, "skill": "Marketing" },
    { "id": 18, "skill": "Social Media Management" },
    { "id": 19, "skill": "Negotiation" },
    { "id": 20, "skill": "Financial Analysis" },
    { "id": 21, "skill": "Budgeting" },
    { "id": 22, "skill": "Accounting" },
    { "id": 23, "skill": "Business Development" },
    { "id": 24, "skill": "Networking" },
    { "id": 25, "skill": "Public Speaking" },
    { "id": 26, "skill": "Presentation Skills" },
    { "id": 27, "skill": "Entrepreneurship" },
    { "id": 28, "skill": "Technical Support" },
    { "id": 29, "skill": "Programming" },
    { "id": 30, "skill": "Software Development" },
    { "id": 31, "skill": "Web Development" },
    { "id": 32, "skill": "Mobile App Development" },
    { "id": 33, "skill": "Cloud Computing" },
    { "id": 34, "skill": "Artificial Intelligence" },
    { "id": 35, "skill": "Machine Learning" },
    { "id": 36, "skill": "Data Science" },
    { "id": 37, "skill": "Statistical Analysis" },
    { "id": 38, "skill": "UX/UI Design" },
    { "id": 39, "skill": "Graphic Design" },
    { "id": 40, "skill": "Video Production" },
    { "id": 41, "skill": "Content Creation" },
    { "id": 42, "skill": "Writing and Editing" },
    { "id": 43, "skill": "SEO/SEM Marketing" },
    { "id": 44, "skill": "Digital Marketing" },
    { "id": 45, "skill": "Search Engine Marketing" },
    { "id": 46, "skill": "Cybersecurity" },
    { "id": 47, "skill": "Information Technology" },
    { "id": 48, "skill": "Network Administration" },
    { "id": 49, "skill": "Database Management" },
    { "id": 50, "skill": "Systems Administration" }
  ]
  