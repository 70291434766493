export const RESET_ALL = "RESET_ALL"
export const SIDEBAR_TOGGLER = "SIDEBAR_TOGGLER";
export const COURSE_SIDEBAR_TOGGLER = "COURSE_SIDEBAR_TOGGLER";
export const ADD_CART_POPUP_TOGGLER = "ADD_CART_POPUP_TOGGLER";
export const NOTIFICATION_TOGGLER = "NOTIFICATION_TOGGLER";
export const PROFILE_TOGGLER = "PROFILE_TOGGLER";
export const HELP_FORM_DD_TOGGLER = "HELP_FORM_DD_TOGGLER";
export const MULTILANG_TOGGLER = "MULTILANG_TOGGLER";
export const COUNTRY_DD_TOGGLER = "COUNTRY_DD_TOGGLER";

export const PROFILE_TAB_CHANGER = "PROFILE_TAB_CHANGER";

export const SEND_PROFESSIONS = "SEND_PROFESSIONS";
export const SEND_INTERESTED_FIELD = "SEND_INTERESTED_FIELD";


export const GET_PROFILE_DATA = "GET_PROFILE_DATA";




export const LANG = "LANG";











