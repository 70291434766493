import "./CourseSidebar.scss";
import { Fragment, useEffect, useState } from "react";
import { menuList } from "../../utils/data/data";
import { icons, logoImage } from "../../utils/images/images";
import { useDispatch, useSelector } from "react-redux";
import {
  courseSidebarToggler,
  resetAllToggler,
  sidebarToggler,
} from "../../store/actions";
import { NavLink } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

import { IoLogoGitlab } from "react-icons/io5";

import { RxDashboard } from "react-icons/rx";
import { LiaHomeSolid } from "react-icons/lia";
import { GoGlobe } from "react-icons/go";
import { LuBookmark } from "react-icons/lu";
import { IoBookOutline } from "react-icons/io5";
import TabDivider from "../../authPages/components/authSidebar/tabDivider/TabDivider";

const CourseSidebar = () => {
  const { isresume } = useSelector((data) => data.getProfileDataReducer);

  const menuData = [
    {
      id: 0,
      icon: <LiaHomeSolid />,
      text: "home",
      path: "/auth/home",
    },
    {
      id: 1,
      icon: <GoGlobe />,
      text: "explore",
      path: "/auth/explore",
    },

    {
      id: 2,
      icon: <LuBookmark />,
      text: "saved",
      path: "/auth/saved",
    },
    {
      id: 3,
      icon: <IoBookOutline />,
      text: "Resume Builder",
      path: isresume ? "/auth/resume" : "/auth/resumebuilder",
    },
  ];

  const labsData = [
    {
      id: 0,
      icon: <IoLogoGitlab />,
      text: "Machine",
      path: "/auth/home",
    },
  ];

  const dispatch = useDispatch();

  const courseSidebarStatus = useSelector(
    (state) => state.toggleReducer.courseSidebarStatus
  );

  return (
    <Fragment>
      <div
        className={`course_side_bar ${courseSidebarStatus ? "open" : "close"}`}
      >
        <div className="logo_line">
          <img src={logoImage.logo} alt="logo" className="logo" />
          <button
            className="button"
            onClick={() =>
              dispatch({ type: courseSidebarToggler(), payload: false })
            }
          >
            <img src={icons.whiteClose} alt="close" />
          </button>
        </div>
        <div className="course_screen_links">
          <NavLink to="/auth/home">
            <button type="button" className="navbtn">
              <RxDashboard />
              Dashboard
            </button>
          </NavLink>


{/* LMS */}
<TabDivider title="LMS" side={true} />
          {menuData.map((data) => {
            return (
              <Fragment key={data.id}>
                <NavLink to={data.path}>
                  <button type="button" className="navbtn">
                    {data.icon}
                    {data.text}
                  </button>
                </NavLink>
              </Fragment>
            );
          })}

{/* Labs */}
<TabDivider title="Labs" side={true} />
{labsData.map((data) => {
            return (
              <Fragment key={data.id}>
                <NavLink to={data.path}>
                  <button type="button" className="navbtn">
                    {data.icon}
                    {data.text}
                  </button>
                </NavLink>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default CourseSidebar;
