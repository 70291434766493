import "./OverView.scss";
import { images } from "../../../utils/images/images";
import triangle from "../../../assets/images/labs/triangle.svg";
import { DiLinux } from "react-icons/di";
import { ImWindows } from "react-icons/im";
import { LuFlag } from "react-icons/lu";
import { GoHash } from "react-icons/go";
import MachineInAction from "./mochineInAction/MachineInAction";
import CommonCard from "../../../components/commonCard/CommonCard";

const OverView = () => {
  const listData = [
    {
      id: 0,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <DiLinux />,
    },
    {
      id: 1,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Active",
      duration: "Week 1",
      system: <ImWindows />,
    },
    {
      id: 2,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <ImWindows />,
    },
    {
      id: 3,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Active",
      duration: "Week 1",
      system: <DiLinux />,
    },
    {
      id: 4,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <ImWindows />,
    },
    {
      id: 5,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <ImWindows />,
    },
    {
      id: 6,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <DiLinux />,
    },
    {
      id: 7,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Active",
      duration: "Week 1",
      system: <ImWindows />,
    },
    {
      id: 8,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Expired",
      duration: "Week 1",
      system: <DiLinux />,
    },
    {
      id: 9,
      img: images.machineA,
      title: "Bizness",
      level: "Easy",
      flag: 20,
      hash: 25,
      status: "Active",
      duration: "Week 1",
      system: <ImWindows />,
    },
  ];

  const followers = [
    {
      id: 0,
      img: images.followerA,
      name: "Lorem",
      count: 905,
    },
    {
      id: 1,
      img: images.followerB,
      name: "Ipsum",
      count: 954,
    },
    {
      id: 2,
      img: images.followerC,
      name: "Dolor",
      count: 945,
    },
    {
      id: 3,
      img: images.followerD,
      name: "Lorem",
      count: 444,
    },
    {
      id: 4,
      img: images.followerA,
      name: "Amet",
      count: 905,
    },
    {
      id: 5,
      img: images.followerA,
      name: "Lorem",
      count: 905,
    },
  ];
  return (
    <div className="m_overview">
      <div className="left">
        <MachineInAction />
        <h6>Season 4 machines</h6>
        <div className="machine_list">
          {listData.map((data) => {
            return (
              <CommonCard style={{ marginBottom: "16px" }}>
                <div className="machine_card" key={data.id}>
                  <div className="left_view">
                    <div className="icon_box_wrap">
                      <img src={data.img} alt="machine" />
                      <div className="system">{data.system}</div>
                    </div>
                    <div className="text">
                      <p>{data.title}</p>
                      <p>
                        <span>{data.level}</span>
                      </p>
                    </div>
                  </div>
                  <div className="right">
                    <div className="info_wrap">
                      <p>+{data.flag}</p>
                      <div className="round">
                        <LuFlag />
                      </div>
                    </div>
                    <div className="info_wrap">
                      <p>+{data.hash}</p>
                      <div className="round">
                        <GoHash />
                      </div>
                    </div>

                    <div className={`status ${data.status}`}>
                      <p>{data.status}</p>
                    </div>
                    <div className="duration">
                      <p>{data.duration}</p>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </div>
      </div>
      <div className="counter">
        <CommonCard>
          <div className="top_box">
            <h6>0/26</h6>
            <p>Flag Pwned</p>
          </div>
        </CommonCard>

        <CommonCard style={{ marginTop: "8px" }}>
          <div className="count_box">
            <div className="machine_cover">
              <img src={images.machineA} alt="machine" />
            </div>

            <h5>Unranked</h5>

            <p>Your Tier</p>
            <p>-</p>
            <p>Your Points</p>
            <p>-</p>
          </div>
        </CommonCard>

        <CommonCard style={{ marginTop: "8px" }}>
          <div className="followers">
            <p className="title">Followings</p>
            {followers.map((data) => {
              return (
                <div className="follower_card" key={data.id}>
                  <div className="personal_info">
                    <div className="icon_wrap">
                      <img src={data.img} alt="" />
                    </div>
                    <p>{data.name}</p>
                  </div>
                  <p>{data.count}</p>
                </div>
              );
            })}
          </div>
        </CommonCard>
      </div>
    </div>
  );
};

export default OverView;
