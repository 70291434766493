import "./ConnectToNetwork.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdClose } from "react-icons/io";
import CommonCard from "../../../components/commonCard/CommonCard";
import { Fragment, useState } from "react";
import { BsCloudPlus } from "react-icons/bs";
import { PiStack } from "react-icons/pi";
import { MdArrowBackIosNew } from "react-icons/md";

const ConnectToNetwork = (props) => {
  const [screen, setScreen] = useState("a");



  return (
    <Offcanvas
      show={props.connect}
      onHide={props.onClose}
      {...props}
      className="connect_to_network"
    >
      <Offcanvas.Body>
        <div className="top_titles">
          <h6>Connect To Cybergain</h6>
          <button type="button" className="close" onClick={props.onClose}>
            <IoMdClose />
          </button>
          {["b", "c"].includes(screen) && (
            <button
              type="button"
              className="back"
              onClick={() => setScreen(screen === "b" ? "a" : "b")}
            >
              <MdArrowBackIosNew />
            </button>
          )}
        </div>

        {screen === "a" && (
          <Fragment>
            <CommonCard style={{ marginTop: "16px" }}>
              <div className="connet_card" onClick={() => setScreen("b")}>
                <h6>Machines</h6>
                <p>Play Machines</p>
                <div className="machine_status">
                  <p className="status">· OFFLINE</p>
                  <p>LOREM IPSUM</p>
                </div>
              </div>
            </CommonCard>

            <CommonCard style={{ marginTop: "16px" }}>
              <div className="connet_card" onClick={() => setScreen("b")}>
                <h6>Starting Point</h6>
                <p>Play Starting Point</p>
                <div className="machine_status">
                  <p className="status">· OFFLINE</p>
                  <p>LOREM IPSUM</p>
                </div>
              </div>
            </CommonCard>
          </Fragment>
        )}

        {screen === "b" && (
          <Fragment>
            <CommonCard style={{ marginTop: "16px" }}>
              <div className="vpn_pawn_card" onClick={() => setScreen("c")}>
                <div className="icon_circle">
                  <BsCloudPlus />
                </div>
                <div className="text">
                  <h6>OpenVPN</h6>
                  <p>The OG way of connecting to a machine.</p>
                </div>
              </div>
            </CommonCard>           
          </Fragment>
        )}

        {screen === "c" && (
          <Fragment>
            <div className="off_line">
              <div className="circle"></div><p>OFFLINE</p>
            </div>
          </Fragment>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ConnectToNetwork;
