import "./CommonCard.scss";

const CommonCard = (props) => {
  return (
    <div className={`common_card ${props.classList}`} data-wow-delay={props.delay} style={props.style}  >
      {props.children}
      <span className="corner a"></span>
      <span className="corner b"></span>
      <span className="corner c"></span>
      <span className="corner d"></span>
    </div>
  );
};

export default CommonCard;
