import "./MachineTable.scss";
import { images } from "../../../utils/images/images";
import { DiLinux } from "react-icons/di";
import { ImWindows } from "react-icons/im";
import { IoIosStarOutline } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { HiOutlineHashtag } from "react-icons/hi2";
import { TfiAngleRight } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";

const MachineTable = () => {
  const tableData = [
    {
      id: 0,
      machineimg: images.machineA,
      name: "Headless",
      dificculty: "Easy",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <DiLinux />,
      status: "Active",
      count: [20, 30, 50, 80, 100, 60, 30, 40],
    },
    {
      id: 1,
      machineimg: images.machineA,
      name: "FormulaX",
      dificculty: "Easy",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <ImWindows />,
      status: "Seasonal",
      count: [20, 30, 50, 80, 100, 60, 30, 20],
    },
    {
      id: 2,
      machineimg: images.machineA,
      name: "Crafty",
      dificculty: "Hard",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <DiLinux />,
      status: "Active",
      count: [20, 30, 50, 80, 100, 60, 30, 30],
    },
    {
      id: 3,
      machineimg: images.machineA,
      name: "Analysis",
      dificculty: "Normal",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <ImWindows />,
      status: "Inactive",
      count: [20, 30, 50, 80, 100, 60, 30, 10],
    },
    {
      id: 4,
      machineimg: images.machineA,
      name: "Surveillance",
      dificculty: "Hard",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <ImWindows />,
      status: "Inactive",
      count: [20, 30, 50, 80, 100, 60, 30, 10],
    },
    {
      id: 5,
      machineimg: images.machineA,
      name: "WifineticTwo",
      dificculty: "Easy",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <DiLinux />,
      status: "Active",
      count: [20, 30, 50, 80, 100, 60, 30, 10],
    },
    {
      id: 6,
      machineimg: images.machineA,
      name: "Perfection",
      dificculty: "Hard",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <ImWindows />,
      status: "Inactive",
      count: [20, 30, 50, 80, 100, 60, 30, 50],
    },
    {
      id: 7,
      machineimg: images.machineA,
      name: "Skyfall",
      dificculty: "Easy",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <DiLinux />,
      status: "Inactive",
      count: [20, 30, 50, 80, 100, 60, 30, 10],
    },
    {
      id: 8,
      machineimg: images.machineA,
      name: "Analysis",
      dificculty: "Easy",
      rating: 4.5,
      users: 350,
      system: 3069,
      pill: "Seasonal",
      typeIcon: <DiLinux />,
      status: "Seasonal",
      count: [20, 30, 50, 80, 100, 60, 30, 50],
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="machine_table_wrap">
      <table>
        <thead>
          <tr>
            <th>Machine</th>
            <th>Difficulty Rating</th>
            <th>Rating</th>
            <th>User Owns</th>
            <th>System Owns</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data) => {
            return (
              <tr
                onClick={() => navigate("/auth/machine/headless")}
                key={data.id}
              >
                <td className="about_machine">
                  <div className="machine_wrap">
                    <div className="img_box">
                      <img src={data.machineimg} alt="machine" />
                      <div className="system_wrap">{data.typeIcon}</div>
                    </div>
                    <div className="text">
                      <p>{data.name}</p>
                      <p className="small">{data.dificculty}</p>
                    </div>
                  </div>
                </td>
                <td className="chart">
                  {data.count.map((num) => {
                    return (
                      <div
                      key={num}
                        className="bar_line"
                        style={{ height:`${num/2}px` }}                        
                      >
                        <div className="tip">
                          <p>Medium</p>
                          <p>{num} Votes</p>
                        </div>
                      </div>
                    );
                  })}
                </td>
                <td className="num_info">
                  <p>
                    <span>
                      <IoIosStarOutline />
                    </span>
                    {data.rating}
                  </p>
                </td>
                <td className="num_info">
                  <p>
                    <span>
                      <CiUser />
                    </span>
                    {data.users}
                  </p>
                </td>
                <td className="num_info">
                  <p>
                    <span>
                      <HiOutlineHashtag />
                    </span>
                    {data.system}
                  </p>
                </td>
                <td className="status">
                  {data.status ? (
                    <p className={data.status}>{data.status}</p>
                  ) : (
                    ""
                  )}
                </td>
                <td className="view">
                  <button type="button">
                    <TfiAngleRight />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MachineTable;
