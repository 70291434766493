import { icon } from "../../../utils/images/icons";
import "./BundleCard.scss";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apidata";

import { useState } from "react";
import CommonCard from "../../../components/commonCard/CommonCard";

const BundleCard = ({
  _id,
  image,
  name,
  is_course,
  small_description,

  course_time,

  students,
  isSave,
  index,
  redirectTo,
  saveHandler,
}) => {
  const [saveStatus, setSaveStatus] = useState(isSave);

  const navigate = useNavigate();

  const saveCourse = (e, cousrseId) => {
    setSaveStatus((prev) => !prev);
    e.stopPropagation();
    saveHandler(cousrseId);
  };
  return (
    <CommonCard>
      <div className="bundle_card_wrap">
        <button type="button" onClick={(e) => saveCourse(e, _id)}>
          {saveStatus ? (
            <img src={icon.saved} alt="save" />
          ) : (
            <img src={icon.save} alt="save" />
          )}
        </button>

        <div
          className="explorecourse_card wow zoomIn"
          data-wow-delay={`${(0.2 * index) % 3}s`}
          onClick={() => navigate(redirectTo)}
        >
          <div className="poster_wrap">
            <span className="tag">{is_course}</span>
            <img src={`${baseUrl}/${image}`} alt="poster" />
          </div>
          <h2>{name}</h2>
          <p className="desc">{small_description}</p>
          <div className="counter_info">
            {students && (
              <div className="info">
                <img src={icon.students} alt="student" />
                <p>{students}</p>
              </div>
            )}
            {course_time && (
              <div className="info">
                <img src={icon.clock} alt="student" />
                <p>{course_time}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </CommonCard>
  );
};

export default BundleCard;
