import "./MachineCard.scss";
import CommonCard from "../../../../components/commonCard/CommonCard";
import PrimaryBtn from "../../../../components/primaryBtn/PrimaryBtn";
import { PiPower } from "react-icons/pi";

const MachineCard = ({machineImg, systemIcon, pillText, title, level, btnText, clickHandler}) => {
  return (
    <CommonCard>
      <div className="machine_card" onClick={clickHandler}>
        <div className="img_box">
          <img src={machineImg} alt="machine" />
          <div className="system_wrap">
            {systemIcon}
          </div>
        </div>
        <div className="machine_info">
          <div className="pill">
            <p>{pillText}</p>
          </div>
          <h6>{title}</h6>
          <p className="t-i-12">{level}</p>
          <PrimaryBtn title={btnText} theme="dark" icon={<PiPower />} />
        </div>
      </div>
    </CommonCard>
  );
};

export default MachineCard;
