import "./MachineFaq.scss";
import Accordion from "react-bootstrap/Accordion";
import { TfiAngleDoubleUp } from "react-icons/tfi";

const MachineFaq = () => {
  return (
    <div className="machine_faq">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <p>Lorem ipsum dolor sit amet?</p><TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Repudiandae provident aliquam hic enim soluta minus cum atque
              mollitia vero cumque.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing?</p> <TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              inventore assumenda, accusantium sit natus ipsam. Itaque a earum
              dolorum, harum eius facere unde dolore similique
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing?</p> <TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              inventore assumenda, accusantium sit natus ipsam. Itaque a earum
              dolorum, harum eius facere unde dolore similique
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing?</p> <TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              inventore assumenda, accusantium sit natus ipsam. Itaque a earum
              dolorum, harum eius facere unde dolore similique.sit amet
              consectetur adipisicing elit. Animi inventore assumenda,
              accusantium sit natus ipsam
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing?</p> <TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              inventore assumenda, accusantium sit natus ipsam. Itaque a earum
              dolorum, harum eius facere unde dolore similique assumenda,
              accusantium sit natus ipsam. Itaque a earum dolorum, harum eius
              facere unde dolore similique.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <p>
              Lorem, dolor sit amet consecte ipsum dolor sit amet consectetur
              adipisicing?
            </p> <TfiAngleDoubleUp />
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
              inventore assumenda, accusantium sit natus ipsam. Itaque a earum
              dolorum, harum eius facere unde dolore similique
            </p>
          </Accordion.Body>
        </Accordion.Item>
        v
      </Accordion>
    </div>
  );
};
export default MachineFaq;
