import "./AuthLayout.scss";
import { Fragment, useContext, useState } from "react";
import CoursesHeader from "./components/coursesheader/CoursesHeader";
import { redirectContext } from "../context/RoutingContext";
import { useEffect } from "react";
import AuthSidebar from "./components/authSidebar/AuthSidebar";
import ConnectToNetwork from "../labs/other/connectToNetwork/ConnectToNetwork";

const AuthLayout = ({ children, isLabs }) => {
  const { getProfileApi, getCartApi, getCouponApi } =
    useContext(redirectContext);

  useEffect(() => {
    getProfileApi();
    getCartApi();
    getCouponApi();
  }, []);

  const [side, setSide] = useState(true);
  const [connect, setConnect] = useState(false);

  const openConnectToNetwork = () => {
    setConnect(true);
  };

  const closeConnectToNetwork = () => {
    setConnect(false);
  };

  return (
    <Fragment>
      {/* <div className="header_filler"></div> */}
      <div className="layouot_parent">
        {/* Menu left side bar use for desktop Screen */}

        <AuthSidebar side={side} setSide={setSide} />

        {/* Connect To Network only for Labs right side bar */}
        {isLabs && (
          <ConnectToNetwork
            show={connect}
            placement="end"
            onOpen={openConnectToNetwork}
            onClose={closeConnectToNetwork}
          />
        )}

        <div className={`page_view ${side ? "open" : "close"}`}>
          <CoursesHeader
            isLabs={isLabs}
            openConnectToNetwork={openConnectToNetwork}
          />
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default AuthLayout;
