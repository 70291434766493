import "./MachineFilter.scss";
import { IoSearchOutline } from "react-icons/io5";
import Dropdown from "react-bootstrap/Dropdown";

const MachineFilter = ({
  status,
  setStatus,
  sort,
  setSort,
  difficulty,
  setDifficulty,
}) => {
  return (
    <div className="machine_filter">
      <div className="search_field">
        <IoSearchOutline />
        <input type="text" placeholder="Search Active Machines..." />
      </div>
      <div className="dd_wraper">
        {/* Status Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-basic">
            Status : <span>{status}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setStatus("Complete")}>
              Complete
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setStatus("Incomplete")}>
              Incomplete
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setStatus("Both")}>
              Both
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Short By Dropdown */}

        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-basic">
            Sort By : <span>{sort}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setSort("Release date")}>
              Release date
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSort("Name")}>Name</Dropdown.Item>
            <Dropdown.Item onClick={() => setSort("User Owns")}>
              User Owns
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSort("System Owns")}>
              System Owns
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSort("Rating")}>
              Rating
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setSort("User Difficulty")}>
              User Difficulty
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Difficulty */}

        <Dropdown>
          <Dropdown.Toggle variant="none" id="dropdown-basic">
            Difficulty : <span>{difficulty}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setDifficulty("Easy")}>
              Easy
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDifficulty("Medium")}>
              Medium
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDifficulty("Hard")}>
              Hard
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDifficulty("Insane")}>
              Insane
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default MachineFilter;
