import "./HowToPlayModal.scss";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { images } from "../../../utils/images/images";
import { SiCyberdefenders } from "react-icons/si";
import { GiCyberEye } from "react-icons/gi";
import { GrTechnology } from "react-icons/gr";
import { useRef, useState } from "react";

const HowToPlayModal = (props) => {
  const swiperTraget = useRef(null);

  const [activeSlide, setActiveSlide] = useState(0);

  const goToSlide = (num) => {
    swiperTraget.current?.swiper.slideTo(num);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="how_to_play"
    >
      <Modal.Body>
        <Swiper
          ref={swiperTraget}
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(slide) => setActiveSlide(slide.activeIndex)}
        >
          <SwiperSlide>
            <div className="slide_wraper">
              <img src={images.howToPlay} alt="Machine" className="how_to" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
                architecto totam non odio velit in officiis ab. Debitis,
                corrupti iusto.
              </p>
              <PrimaryBtn
                title="Get Started"
                theme="dark"
                clickHandler={() => goToSlide(1)}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide_wraper">
              <h6>How To Play The Seasonal</h6>
              <div className="tri_grid">
                <div className="tri_card">
                  <SiCyberdefenders />
                  <p>lorem ipsum amet</p>
                </div>
                <div className="tri_card">
                  <GiCyberEye />
                  <p>lorem ipsum amet</p>
                </div>
                <div className="tri_card">
                  <GrTechnology />
                  <p>lorem ipsum amet</p>
                </div>
              </div>
              <div className="bi_grid">
                <div className="bi_card">
                  <h5>12</h5>
                  <p>Weeks</p>
                </div>
                <div className="bi_card">
                  <h5>14</h5>
                  <p>Machines</p>
                </div>
              </div>
              <PrimaryBtn
                title="Continue"
                theme="dark"
                clickHandler={() => goToSlide(2)}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide_wraper">
              <h6>What are Points?</h6>
              <img src={images.howA} alt="machine" className="how_to" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repudiandae quasi ipsum, fugiat sequi voluptatibus saepe qui
                modi ipsam velit tempore!
              </p>
              <PrimaryBtn
                title="Continue"
                theme="dark"
                clickHandler={() => goToSlide(3)}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide_wraper">
              <h6>What are Tiers?</h6>
              <img src={images.howB} alt="machine" className="how_to" />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repudiandae quasi ipsum, fugiat sequi voluptatibus saepe qui
                modi ipsam velit tempore!
              </p>
              <PrimaryBtn title="Close" theme="dark" clickHandler={props.onHide} />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="slider_navigation">
          <button
            type="button"
            className={activeSlide == 0 ? "active" : ""}
            onClick={() => goToSlide(0)}
          ></button>
          <button
            type="button"
            className={activeSlide == 1 ? "active" : ""}
            onClick={() => goToSlide(1)}
          ></button>
          <button
            type="button"
            className={activeSlide == 2 ? "active" : ""}
            onClick={() => goToSlide(2)}
          ></button>
          <button
            type="button"
            className={activeSlide == 3 ? "active" : ""}
            onClick={() => goToSlide(3)}
          ></button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowToPlayModal;
