import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn"

const NextPrevBtns = ({onNext, onPrev, backDisabled, nextDisabled}) => {

    return(
        <div className="navigate_btns">
        {/* <button
          type="button"
          className="primarybtn"
          onClick={onPrev}
          disabled={backDisabled}
        >
          Back
        </button> */}
        <PrimaryBtn title="Back" clickHandler={onPrev} isDesabled={backDisabled} />
        {/* <button
          type="button"
          className="primarybtn"
          onClick={onNext}
          disabled={nextDisabled}
        >
          Next
        </button> */}
        <PrimaryBtn title="Next" clickHandler={onNext} isDesabled={nextDisabled} />
      </div>
    )
}

export default NextPrevBtns;