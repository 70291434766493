import { useState, useEffect, useContext } from "react";
import { redirectContext } from "../../context/RoutingContext";
import "./SignIn.scss";
import { Fragment } from "react";
import LoginHeader from "../components/loginHeader/LoginHeader";
import { icon } from "../../utils/images/icons";
import FieldErrorMessage from "../components/errorMessage/FieldErrorMessage";
import WOW from "wow.js";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { baseUrl, signIn } from "../../utils/apidata";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/primaryBtn/PrimaryBtn";
import FieldCover from "../../components/fieldCover/FieldCover";
import ParticlesBg from "../../components/particlesbg/ParticlesBg";

const SignIn = () => {
  const {
    signUpHandler,
    toastError,
    toastSuccess,
    goToAuthHome,
    goToForgotPassword,
  } = useContext(redirectContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("invalid email formate!")
      .required("email is required!"),
    password: Yup.string().required("password is required!"),
  });

  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  const [eye, setEye] = useState(false);

  const onSubmit = async (values) => {
    // const pageId = localStorage.getItem("pageId");
    setLoader(true);
    try {
      const response = await axios.post(`${baseUrl}/${signIn}`, values);

      if (response.data.success) {
        toastSuccess("Sign In Success!");
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("name", response?.data?.data?.name);
        localStorage.setItem("email", response?.data?.data?.email);
        localStorage.setItem("country", response?.data?.data?.country);

        goToAuthHome();

        setLoader(false);
        //         if(pageId){
        // navigate(`/explore/${pageId}`);
        //         }else{
        //           goToAuthHome();
        //         }
      } else {
        toastError(response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log("ERROR:::", error);
      setLoader(false);
      if (error.message) {
        toastError(error.message || "Something Went Wrong!");
      }
    }
  };

  return (
    <Fragment>
      <ParticlesBg />
      <LoginHeader
        left={false}
        right={false}
        progress={true}
        progressCount={0}
      />
      <section className="login_form">
        <div className="auth_container">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="title">
                <h1 className="small_title wow fadeInUp">Sign In</h1>
                <div className="suggetion wow fadeInUp">
                  <p>New user?</p>
                  <button type="button" onClick={signUpHandler}>
                    <p>Get started for free</p>
                  </button>
                </div>
              </div>

              <div className="auth_field wow fadeInUp">
                <label htmlFor="Email">email</label>
                <FieldCover>
                  <div className="input_wrap">
                    <Field
                      type="email"
                      placeholder="Email"
                      id="Email"
                      name="email"
                      autoComplete="off"
                    />
                    <img className="field_icon" src={icon.email} alt="email" />
                  </div>
                </FieldCover>
                <ErrorMessage name="email" component={FieldErrorMessage} />
              </div>

              <div className="auth_field mt wow fadeInUp">
                <label htmlFor="Password">password</label>
                <FieldCover>
                  <div className="input_wrap">
                    <Field
                      type={`${eye ? "text" : "password"}`}
                      placeholder="password"
                      id="Password"
                      name="password"
                      autoComplete="off"
                    />

                    <button type="button" onClick={() => setEye(!eye)}>
                      {eye ? (
                        <img src={icon.eyeSlash} alt="email" />
                      ) : (
                        <img src={icon.eye} alt="email" />
                      )}
                    </button>
                  </div>
                </FieldCover>
                <ErrorMessage name="password" component={FieldErrorMessage} />
              </div>
              <button
                type="button"
                className="forgot_password wow fadeInUp"
                onClick={goToForgotPassword}
              >
                Forgot Password
              </button>
              <div className="btns">
                {loader ? (
                  <PrimaryBtn title="Loading..." disabled="true" />
                ) : (
                  <PrimaryBtn title="Sign In" type="submit" />
                )}

                <PrimaryBtn
                  title="Get started for free"
                  theme="dark"
                  clickHandler={signUpHandler}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </Fragment>
  );
};

export default SignIn;
