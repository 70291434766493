export const arabic = {
  
  // Home

  homeLandingTitle:
    "الأمن السيبراني الحقيقي يستعد لما هو قادم، وليس لما كان أخيرًا",
  homeLandingPara:
    "أدخل أكاديمية CyberGain بوابتك إلى عالم التدريب على الأمن السيبراني المتطور. انغمس في مجموعتنا المتنوعة من مختبرات التدريب العملي وموارد التعلم الغنية، المصممة لتمكين عشاق التكنولوجيا من جميع الخلفيات من إتقان فن الأمن السيبراني والبقاء في المقدمة في هذا المشهد المتطور باستمرار.",
  homeLandingBtn: "اشتراك",

  courseCardOneTitle: "مبتدئ",
  courseCardOnePara:
    "بدأت للتو في مجال الأمن السيبراني، حريصة على التعلم والاستكشاف، ومجهزة بالمعرفة التأسيسية في مبادئ الشبكات والأمن. متابعة التعليم والشهادات بنشاط والمشاركة في مجالات الأمن السيبراني المختلفة.",

  courseCardTwoTitle: "متوسط",
  courseCardTwoPara:
    "شغوف بتوسيع المعرفة وتحسين المهارات بما يتجاوز الأساسيات. يمتلك أساسًا متينًا في مجال الشبكات وأمن الأنظمة والخبرة العملية. تطوير التعليم بشكل فعال من خلال الدورات والشهادات المتخصصة.",

  courseCardThreeTitle: "احترافي",
  courseCardThreePara:
    "مدفوعة للتفوق وتعزيز الخبرة. لديك معرفة واسعة في الشبكات وأمن النظام والخبرة العملية. تعزيز المهارات باستمرار من خلال الدورات والشهادات المتقدمة، واستكشاف المجالات التفصيلية لمجالات الأمن السيبراني.",

  courseCardFourTitle: "يتقن",
  courseCardFourPara:
    "مكرسة لدفع الحدود والابتكار في هذا المجال. يتمتع بعمق لا مثيل له من المعرفة في مجال الشبكات وأمن النظام والخبرة العملية الواسعة. يسعى إلى تحقيق أحدث التطورات من خلال الأبحاث الرائدة والشهادات الرائدة والتقدم الرائد في مجالات الأمن السيبراني المتنوعة.",

  singlecardTitle: "الربح السيبراني",
  singlecardText: "مركز",

  // availTitle:"الدورات المتاحة",
  // redTitle:"حقل الفريق الأحمر - الهجوم السيبراني.",
  // redPara:"استمتع بتجربة دورة فريقنا الأحمر، المصممة لتعزيز مهاراتك الشخصية وإطلاق العنان لإبداعك، وتزويدك بمهارات قيمة لمهنة ناجحة في مجال الأمن السيبراني.",

  // redC1:"دورة القرصنة الأخلاقية.",
  // redC2:"الذكاء مفتوح المصدر – دورة OSINT.",
  // redC3:"دورة اختراق الدليل النشط.",
  // redC4:"دورة بايثون للشبكات والقرصنة",
  // redC5:"دورة اختبار الاختراق الخارجي.",
  // redC6:"دورة اختبار اختراق تطبيقات الويب.",
  // redC7:"كيفية بناء قائمة مرجعية لدورة الفريق الأحمر.",
  // redC8:"برمجة باش لدورة الفريق الأحمر.",
  // redC9:"دورة Powershell لاختبار الاختراق.",

  // blueTitle:"ملعب الفريق الأزرق - الدفاع السيبراني.",
  // bluePara:"نقدم لكم دورة الفريق الأزرق، وهي مسار شهادة شامل مصمم لتزويدك بالمهارات الأمنية الدفاعية العملية. اكتسب الخبرة في الاستجابة للحوادث، وأمن الشبكات، وتقييم نقاط الضعف، والمزيد، أثناء تطويرك لفهم قوي للممارسات الأمنية الدفاعية. من خلال التدريبات العملية والتدريب الشامل، يمكنك تحليل أنظمة المعلومات وتحصين البيئات الرقمية وضمان الحماية المستمرة ضد التهديدات المتطورة.",
  
  // blueC1:"دورة محلل SOC المستوى 1.",
  // blueC2:"دورة محلل SOC من المستوى 2 + 3.",
  // blueC3:"دورة الطب الشرعي الرقمي.",
  // blueC4:"دورة الطب الشرعي لينكس.",
  // blueC5:"دورة الطب الشرعي ويندوز.",
  // blueC6:"دورة سبلانك للرصد والكشف.",
  // blueC7:"كيفية بناء قائمة مرجعية لدورة الفريق الأزرق",
  // blueC8:"Bash Scripting עבור קורס צוות כחול.",


  homeLeftTitle: "حول مركز سايبر غاين.",
  homeLeftPara:
    "تأسست شركتنا للتدريب على الأمن السيبراني في عام 2021، ومقرها الرئيسي في إسرائيل ولها فروع إضافية في دبي والهند، وهي بمثابة منارة للتعلم الشامل في المشهد الرقمي المتطور باستمرار. مع أكثر من ستة أجيال من المدربين، يقدم مركزنا مزيجًا ديناميكيًا من الخبرة والابتكار. نحن نعمل على تمكين المتعلمين من خلال المختبرات العملية والمحاكاة، وغمرهم في سيناريوهات الحياة الواقعية المستمدة من حوادث الماضي. يعزز هذا النهج الشامل فهمًا عميقًا للأمن السيبراني، ويزود المتدربين لدينا بالمهارات والبصيرة اللازمة للتنقل والحماية ضد التهديدات الحديثة.",
  homeLeftBtn: "تعرف أكثر",
  goRightBtn:"للجامعات",
  

  homeRightTitle: "الجامعات",
  homeRightPara:
    "تم تصميم برنامج مؤسستنا بدقة لتلبية المتطلبات الفريدة لكل شركة. من خلال تصميم خطة مخصصة، نقدم مجموعة من الخيارات - التدريب عبر الإنترنت أو التدريب الشخصي، والوصول إلى المختبر، وموارد الفيديو، وشهادة CyberGain، والشهادات الدولية - لتتوافق مع تفضيلاتهم المحددة. يتمتع العملاء بالمرونة للاختيار من كتالوج الدورات التدريبية المتنوعة لدينا أو طلب منهج دراسي مخصص. ما عليك سوى التواصل معنا وسنعمل بشكل تعاوني على إنشاء خطة تناسب احتياجاتك تمامًا. نحن نوفر مرونة كاملة، وكل شيء مطروح للمناقشة.",
  homeRightBtn:"حجز اجتماع",
  goLeftBtn:"للجامعات",

  homeOfferTitle: "ابدأ تجربة مجانية مدتها 15 يومًا.",
  homeOfferPara:
    "استكشف مدونتنا للحصول على أحدث المعلومات في مجال الأمن السيبراني — النصائح الأساسية وتحديثات التدريب وأخبار الصناعة. احصل على رؤى قيمة حول البقاء آمنًا عبر الإنترنت، سواء كنت جديدًا في مجال الأمن السيبراني أو محترفًا متمرسًا. انضم إلينا لتبقى على اطلاع وجاهز لمواجهة التحديات الرقمية.",

  homeBlogTopTitle: "اقرأ منشورات مدونتنا المثيرة للاهتمام",
  homeBlogMainLabel: "تعلم عميق",
  homeBlogMainTitle:
    "الكشف عن التهديد الخفي: التعلم العميق يفتح المجال لهجمات القنوات الجانبية الصوتية على لوحات المفاتيح",
  homeBlogMainPara:
    "في عالمنا المترابط بشكل متزايد، حيث يمتد نطاق التكنولوجيا من الأجهزة الشخصية إلى الخدمات عبر الإنترنت، يستمر مشهد التهديدات في التطور. أحد التهديدات الناشئة التي لفتت انتباه الباحثين في مجال الأمن السيبراني هو هجمات القنوات الجانبية الصوتية (ASCA) على لوحات المفاتيح.",
  homeBlogMainBtn: "عرض جميع المدونات",

  


  



// about

aboutLandingTitle:"الأمن السيبراني الحقيقي يستعد ل",
aboutmovingTitle1:"ماذا بعد.",
aboutmovingTitle2:"ليس ما كان آخر.",
aboutLandingText:"بوابتك إلى عالم التدريب على الأمن السيبراني المتطور. انغمس في مجموعتنا المتنوعة من مختبرات التدريب العملي وموارد التعلم الغنية، المصممة لتمكين عشاق التكنولوجيا من جميع الخلفيات من إتقان فن الأمن السيبراني والبقاء في المقدمة في هذا المشهد المتطور باستمرار.",
aboutLandingOfferText1:"15 يومًا تجربة مجانية رقم",
aboutLandingOfferText2:"بطاقة الائتمان مطلوبة",
aboutLandingBtn:"اشتراك",

aboutMarqee:"ارفع مستوى مهاراتك في مجال الأمن السيبراني",

aboutMissionHeading:"بناء مستقبل أكثر أمانا",
aboutMissionPara:"اقرأ المزيد عن التزامنا بالحماية والتمكين في عالم الأمن السيبراني. اكتشف كيف نقود التغيير ونعزز الابتكار ونحمي المشهد الرقمي في الغد.",

aboutMission:"مهمة",
aboutMissionDes:"في عالمنا سريع التغير، حيث اندمجت التكنولوجيا بسلاسة في أنشطتنا اليومية، فإنها تمتلك قوة عظيمة وتأثيرًا هائلاً. ومع ذلك، فإن هذه القوة العظمى تعرضنا أيضًا لمخاطر مختلفة تتطلب اهتمامنا الفوري. تشمل هذه المخاطر تسرب البيانات، وحيل البريد الإلكتروني الماكرة، وتهديد البرامج الضارة، ونقاط الضعف في كلمات المرور الضعيفة، والتهديد الذي يلوح في الأفق بسرقة الهوية، من بين أمور أخرى.",

aboutVision:"فيسون",
aboutVisionDes:"رؤيتنا هي أن نكون شركة رائدة عالميًا في تعليم الأمن السيبراني، مما يجعلها في متناول الجميع. نحن نهدف إلى تمكين الأفراد من جميع الخلفيات ليصبحوا مدافعين ماهرين في العالم الرقمي.",

aboutPurpose:"غاية",
aboutPurposeDes:"التثقيف والتمكين ونشر الوعي وتعزيز الأمن العالمي.",

geniusTeemTitle:"تعرف على فريق العبقرية",
geniusTeemPara:"في Cyber Gain Center، فريقنا هو أكثر من مجرد مجموعة من الخبراء - إنه قوة مخصصة ملتزمة برحلة الأمن السيبراني الخاصة بك. من المحترفين المتمرسين إلى العقول المبتكرة، يقدم كل عضو وجهات نظر فريدة ومعرفة عميقة بالصناعة. معًا، نزدهر بالتعاون، مما يضمن حصول كل متعلم على إرشادات ودعم شخصيين. تعرف على فريقنا الشغوف — الذي يهدف إلى تمكينك من إتقان أساسيات الأمن السيبراني.",

aliName:"علي الزيناتي",
aliPost:"المؤسس المشارك والرئيس التنفيذي",
aliDes:"يتمتع بخبرة واسعة في هندسة البرمجيات والأمن السيبراني. بفضل خبرته العملية في مجالات مختلفة مثل الاستجابة للحوادث، وفريق SOC (الفريق الأزرق)، والفريق الأحمر، واختبار الاختراق، يضمن علي أن برامجنا التدريبية توفر أحدث الأفكار والتقنيات. كما تساهم خبرتهم في تطوير الأعمال في نمو ونجاح مركزنا.",

najeebName:"نجيب ابراهيم",
najeebPost:"قائد الفريق الأزرق",
najeebDes:"يتمتع نجيب إبراهيم بخبرة كمحلل للأمن السيبراني، حيث يكون مسؤولاً عن المساعدة في تشغيل وصيانة منشآت شبكة الكمبيوتر وتقديم المساعدة الفنية أيضًا إجراء التحليل والتهديدات السيبرانية واكتشاف نقاط الضعف فيها ومراقبة التطفلات السيبرانية واستكشاف الأخطاء وإصلاحها والاستجابة لها تم اكتشاف حوادث أمنية من HP arcsight أو SIEM ذات الصلة. IDS/IPS وتطبيقات الأمان الأخرى.",

yaraName:"يارا رمال",
yaraPost:"HR - الموارد البشرية ومدير المكتب",
yaraDes:"حاصل على درجة الماجستير في الخدمات الإنسانية. يتضمن الدور مجموعة واسعة من المسؤوليات، بما في ذلك التوظيف وإدارة المهام الإدارية. يستلزم ذلك إنشاء سيرة ذاتية مثيرة للإعجاب، وإجراء الاستعدادات للمقابلة، وتحسين الملفات الشخصية على LinkedIn. بالإضافة إلى ذلك، فإنه يلعب دورًا حاسمًا في الإشراف على جهود التسويق والإعلان للترويج الفعال لخدمات المركز.",

salehName:"صالح أحمد",
salehPost:"مدرب تكنولوجيا المعلومات وأمن السحابة",
salehDes:"ومع خلفية شاملة في أمن المعلومات والإدارة السيبرانية، عمل صالح في أدوار رئيسية، بما في ذلك مهندس الدعم السحابي ودعم تكنولوجيا المعلومات. من ذوي الخبرة في تقنيات مثل GCP وMicrosoft Azure وMongoDB وأدوات المحاكاة الافتراضية مثل Docker وKubernetes. إن التزامه بتقديم دعم فني استثنائي، إلى جانب فطنته الإدارية، يجعله رصيدًا قيمًا في تشكيل رحلة التعلم لطلابنا",

taleenName:"تالين سكافي",
taleenPost:"مطور الأمن السيبراني",
taleenDes:"خبير ماهر في إجراء ورش العمل الفنية عبر الإنترنت وجلسات تدريب الفريق الأحمر الغامرة. متخصصون في أمان Active Directory، مع خبرة تمتد إلى معلومات التهديدات والاستجابة للحوادث. مسؤول عن تصميم بيئات معملية واقعية، وإدخال نقاط الضعف للتعلم العملي، والمشاركة في اختبار الاختراق وتقييمات الشبكة.",

salmanName:"سلمان",
salmanPost:"مطور الأمن السيبراني",
salmanDes:"سلمان علوان، متخصص مبتكر في الأمن السيبراني، تفوق كطالب سابق في دورة الفريق الأزرق لدينا وحصل على شهادة CSA. متخصص في تصميم المختبرات ونقل المعرفة ويتقن لغة Bash وPython. يشارك سلمان بنشاط في مجال الأمن السيبراني والبرمجة، ويعمل حاليًا على Dork Generator مع مشاريع قادمة تركز على Dork Scanner وSql حقن Vulnerable Scanner/Tester.",

eventHeader:"الأحداث",
eventDes:"اكتشف أحدث ندواتنا عبر الإنترنت والقادمة، وبرامج الأمن السيبراني المتنوعة، ومشاركتنا النشطة في الأحداث البارزة. ابق على اطلاع وتعلم وشارك بينما نساهم ونتعاون في المجال الديناميكي للأمن السيبراني.",

event1Title:"برنامج شباب الأمن السيبراني",
event1Des:"خلال العام السابق، قام مركز Cyber Gain بتنفيذ برنامج شبابي للمدارس المتوسطة العربية. تلقى المركز عروضاً من 10 مدارس مختلفة لتدريس الأمن السيبراني. في المجمل، وصل Cyber Gain Center إلى 20 فصلًا مختلفًا، بمتوسط 25 طالبًا لكل فصل",

event2Title:"حدث في جامعة دبي",
event2Des:"قام السيد علي (المؤسس) بزيارة إلى معهد بيرلا للتكنولوجيا والعلوم، حرم بيلاني في دبي! الجامعة حيث استضاف الأمن السيبراني.",

event3Title:"الأحداث القادمة",
event3Des:"جيسيك في دبي",





// Career

careerLandingTitle:"انضم إلى فريقنا",
careerLandingPara:"الفريق في Cyber Gain Center - مجموعة من الخبراء المتحمسين الملتزمين بالتميز في عالم الأمن السيبراني. بفضل الخبرات المتنوعة والدعم المخصص، نحن هنا لتمكين كل متعلم.",

inviteTitle:"نحن ندعو المواهب الجديدة للانضمام إلى الفريق!",
invitePara:"في حين أن أكاديمية CyberGain ليس لديها حاليًا أي وظائف شاغرة، فإننا نرحب بالأفراد الذين لديهم خلفية تقنية في الأمن السيبراني لتقديم سيرهم الذاتية. في الحالات التي لا يتوفر فيها منصب مناسب على الفور، نحن منفتحون على إنشاء أدوار بناءً على مؤهلات وخبرات المرشحين الاستثنائيين. مع المزيج الصحيح من الشغف والمهارات والخبرة، تتصور أكاديمية CyberGain التوسع جنبًا إلى جنب مع فريق متنوع من الخبراء من جميع أنحاء العالم. يمكن أن تكون مواهبك الفريدة حافزًا لنمونا الجماعي.",

reviewText:"بناءً على المراجعات",

careerFormTitle:"أرسل لنا سيرتك الذاتية",
careerFormPara:"اترك سيرتك الذاتية إذا كنت مهتمًا بوظيفة شاغرة. سوف نتصل بك بالتأكيد.",

careerFormName:"اسم",
careerFormNamePlace:"اسمك",

careerFormEmail:"بريد إلكتروني",
careerFormEmailPlace:"بريدك الالكتروني",

careerFormPhone:"هاتف",
careerFormPhonePlace:"هاتفك",

careerFormTextarea:"أخبر عن مهارتك",

careerFormFileTitle:"قم بتحميل سيرتك الذاتية / السيرة الذاتية هنا",
careerFormFileContent:"تحميل السيرة الذاتية / السيرة الذاتية",

careerFormBtn:"يُقدِّم",

errorFieldRequired:"זהו שדה חובה!",
errorFieldEmail:"الرجاء إدخال تنسيق بريد إلكتروني صالح!",





// Blogs

blogsLandingTitle:"جميع المدونات",
blogsLandingPara:"استكشف مدونتنا للحصول على أحدث المعلومات في مجال الأمن السيبراني — النصائح الأساسية وتحديثات التدريب وأخبار الصناعة. احصل على رؤى قيمة حول البقاء آمنًا عبر الإنترنت، سواء كنت جديدًا في مجال الأمن السيبراني أو محترفًا متمرسًا. انضم إلينا لتبقى على اطلاع وجاهز لمواجهة التحديات الرقمية.",

// Contact

constactLandingTitle:"الاتصال أمر أساسي: تواصل مع Cyber Gain Center اليوم.",
constactLandingPara:"بناء مستقبل أكثر أمانا",
constactLandingBtn:"ابدأ النسخة التجريبية المجانية",

Israel:"إسرائيل",
IsraelAdd:"بيت هام، أبو سنان، 2490500",

UAE:"الإمارات العربية المتحدة",
UAEAdd:" دبي",

India:"الهند",
IndiaAdd:"Cybergain Educenter Private Limited, 2-A/3 S/F Front Side, Asaf Ali Rd, Turkman Gt., داريا غانج, نيودلهي, دلهي, الهند, 110002",

contactFormTitle:"احتاج مساعدة؟",
contactFormPara:"نحن هنا للمساعدة! تواصل معنا للحصول على دعم الخبراء في Cyber Gain Center.",

contactFormName:"اسمك",
contactFormEmail:"بريدك الالكتروني",
contactFormNumber:"رقمك",
contactFormMessage:"رسالة",
contactFormBtn:"أرسل رسالة",




// Header

Home:"بيت",
About:"عن",
Careers:"وظائف",
Blogs:"المدونات",
Contact:"اتصال",

// Footer

footerTitle:"اكتشف المزيد عن الأمن السيبراني مع Cyber Gain Center!",

title1:"ابقى على تواصل",
text1:"تواصل معنا الآن وارفع مستوى الأمن السيبراني الخاص بك",

title2:"ابق على اتصال",
text2:"تواصل معنا على :",

title3:"تسجيل الدخول والاشتراك",
text3:"نقترح عليك تسجيل الدخول إلى بوابتنا",

email:"بريدك الالكتروني",
copyRight1:"© جميع الحقوق محفوظة 2023. الترخيص",
copyRight2:"مدعوم من سوباجرو",





// 404 Page
errorTitle1:"شيء ما",
errorTitle2:"ليس صحيحا.",
errorSubText:"لا يمكننا العثور على الصفحة التي تبحث عنها.",


// Comming Soon Page
soonMainTitle:"قريباً...",
soonSubTitle1:"كن جاهز ل",
soonSubTitle2:"معرفة.",
soonPara:"استعد لتخطي الحدود والابتكار في مجال الأمن السيبراني.",







// Common Terms
prev:"السابق",
next:"التالي",
signUp:"اشتراك",
signIn:"تسجيل الدخول",
backToHome:"العودة إلى المنزل",
KnowMore:"تعرف أكثر",
}
