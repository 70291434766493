import "./StepB.scss";

import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";

const StepB = ({ setStep, name }) => {

  





  const continueHandler = () => {
    SkipHandler();
  };

  const SkipHandler = () => {
    setStep(prev => prev + 1);
  };

  return (
    <div className="step_b">
      <h1 className="small_title wow fadeInUp">
        Hey {name ? name : "User"}, tell us about your department profession
      </h1>
      <p className="wow fadeInUp">Let’s help you setup your learning path</p>

      <div className="btns">
     
        <PrimaryBtn title="Continue" clickHandler={continueHandler} classList="wow fadeInUp" />
      </div>
    </div>
  );
};

export default StepB;
