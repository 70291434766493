import "./MachineDetailDesc.scss";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { MdThumbUp } from "react-icons/md";
import { PiDropFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import pdf from "../../../assets/pdfs/testing.pdf";
import { images } from "../../../utils/images/images";
import { IoFlagOutline } from "react-icons/io5";

const MachineDetailDesc = ({ tab }) => {
  const navigate = useNavigate();

  const activityData = [
    {
      id: 0,
      img: images.activityA,
      title: "Lorem",
      activity: "Owned User flag",
      time: "2 hours ago",
    },
    {
      id: 1,
      img: images.activityB,
      title: "Lorem",
      activity: "Owned User flag",
      time: "3 hours ago",
    },
    {
      id: 2,
      img: images.activityC,
      title: "Lorem",
      activity: "Owned User flag",
      time: "4 hours ago",
    },
    {
      id: 3,
      img: images.activityD,
      title: "Lorem",
      activity: "Owned User flag",
      time: "2 hours ago",
    },

    {
      id: 4,
      img: images.activityE,
      title: "Lorem",
      activity: "Owned User flag",
      time: "6 hours ago",
    },
    {
      id: 5,
      img: images.activityF,
      title: "Lorem",
      activity: "Owned User flag",
      time: "2 hours ago",
    },
  ];

  return (
    <div className="machine_view_desc">
      {tab === "a" && (
        <Fragment>
          <div className="content_desc">
            <div className="overlay"></div>
            <h6>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus!
            </h6>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laudantium, recusandae. Numquam, eaque.
            </p>
            <PrimaryBtn
              title="Play Seasonal Machine"
              theme="dark"
              clickHandler={() => navigate("/auth/machine/overview")}
            />
          </div>
          <div className="release_note">
            <p>Released on 23 March 2024</p>
            <button type="button">
              <MdThumbUp />
            </button>
          </div>
        </Fragment>
      )}

      {tab === "c" && (
        <div className="pdf_reader">
          <iframe src={pdf}></iframe>
        </div>
      )}

      {tab === "e" && (
        <Fragment>
          <div className="activity">
            <div className="info_title">
              <h6>Machine Activity</h6>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
            </div>

            <div className="activity_list">
              {activityData.map((data) => {
                return (

                  <div className="activity_card">
                    <div className="icon_wrap">
                      <IoFlagOutline />
                    </div>
                    
                    <div className="info_wrap">
                      <div className="left">
                        <div className="logo_wraper">
                          <img src={data.img} alt="machine" />
                        </div>
                        <p>{data.title} <span>{data.activity}</span></p>

                      </div>
                      <p>{data.time}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MachineDetailDesc;

