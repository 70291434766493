import { NavLink } from "react-router-dom";
import { Fragment } from "react";
import "./NavBtn.scss";

const NavBtn = ({ icon, text, path, side }) => {
  return (
    <Fragment>
      <NavLink to={path} className="navbtns">
        {icon}
      {side && text}
      </NavLink>
    </Fragment>
  );
};

export default NavBtn;
