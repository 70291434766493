export const GET_SAVED_LIST_REQUEST = "GET_SAVED_LIST_REQUEST";
export const GET_SAVED_LIST_SUCCESS = "GET_SAVED_LIST_SUCCESS";
export const GET_SAVED_LIST_FAIL = "GET_SAVED_LIST_FAIL";


export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";

export const REMOVE_CART_REQUEST = "REMOVE_CART_REQUEST";
export const REMOVE_CART_SUCCESS = "REMOVE_CART_SUCCESS";
export const REMOVE_CART_FAIL = "REMOVE_CART_FAIL";

export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL";

export const EXPLORE_DETAIL_REQUEST = "EXPLORE_DETAIL_REQUEST"; 
export const EXPLORE_DETAIL_SUCCESS = "EXPLORE_DETAIL_SUCCESS"; 
export const EXPLORE_DETAIL_FAIL = "EXPLORE_DETAIL_FAIL"; 

export const GET_COUPONS_REQUEST = "GET_COUPONS_REQUEST"
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS"
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL"

export const APPLY_COUPONS_REQUEST = "APPLY_COUPONS_REQUEST"
export const APPLY_COUPONS_SUCCESS = "APPLY_COUPONS_SUCCESS"
export const APPLY_COUPONS_FAIL = "APPLY_COUPONS_FAIL"
