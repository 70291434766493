import { Fragment } from "react";
import "./FourBoxInfo.scss";
import CommonCard from "../../../components/commonCard/CommonCard";

const FourBoxInfo = ({ title, data = [] }) => {
  return (
    <div className="four_box_info">
      <h5 className="small_title wow fadeInUp">{title}</h5>
      <div className="four_info_grid">
        {data.length > 0 &&
          data.map((item, k) => {
            return (
              <Fragment key={item.id} >
                <CommonCard classList="wow fadeInUp" delay={`${k * 0.1}s`}>
                  <div className="four_info_card">
              <div className="overlay"></div>
                    <div className="icon_box">
                      <img src={item.icon} alt="icon" />
                    </div>
                    <p className="title">{item.title}</p>
                    <p>{item.text}</p>
                  </div>
                </CommonCard>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default FourBoxInfo;
