import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apidata";
import { icon } from "../../../utils/images/icons";
import "./RecommendedModuleCard.scss";
import CommonCard from "../../../components/commonCard/CommonCard";

const RecommendedModuleCard = ({
  image,
  name,
  small_description,
  course_time,
  students,
  index,
  _id,
}) => {
  const navigate = useNavigate();

  return (
    <CommonCard classList="wow fadeInLeft" delay={`${0.3 * index}s`} style={{marginTop:"16px"}}>
      <div
        className="recommended_module_cards"
        onClick={() => navigate(`/explore/${_id}`)}
      >
        <div className="poster_wrap">
          <img src={`${baseUrl}/${image}`} alt="poster" />
        </div>
        <div className="text_info">
          <p className="title">{name}</p>
          <p dangerouslySetInnerHTML={{ __html: small_description }}></p>
          <div className="info_line">
            <div className="info">
              <img src={icon.students} alt="student" />
              <p>{students}</p>
            </div>
            <div className="info">
              <img src={icon.clock} alt="clock" />
              <p>{course_time}</p>
            </div>
          </div>
        </div>
      </div>
    </CommonCard>
  );
};

export default RecommendedModuleCard;
