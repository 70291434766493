import NavBtn from "../coursesheader/navbtn/NavBtn";
import { Fragment } from "react";
import "./AuthSidebar.scss";
import { icon } from "../../../utils/images/icons";
import { useSelector } from "react-redux";
import TabDivider from "./tabDivider/TabDivider";

import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

import { IoLogoGitlab } from "react-icons/io5";

import { RxDashboard } from "react-icons/rx";
import { LiaHomeSolid } from "react-icons/lia";
import { GoGlobe } from "react-icons/go";
import { LuBookmark } from "react-icons/lu";
import { IoBookOutline } from "react-icons/io5";

const AuthSidebar = ({ side, setSide }) => {
  const { isresume } = useSelector((data) => data.getProfileDataReducer);
  const menuData = [
    {
      id: 0,
      icon: <LiaHomeSolid />,
      text: "home",
      path: "/auth/home",
    },
    {
      id: 1,
      icon: <GoGlobe />,
      text: "explore",
      path: "/auth/explore",
    },

    {
      id: 2,
      icon: <LuBookmark />,
      text: "saved",
      path: "/auth/saved",
    },
    {
      id: 3,
      icon: <IoBookOutline />,
      text: "Resume Builder",
      path: isresume ? "/auth/resume" : "/auth/resumebuilder",
    },
  ];

  const labsData = [
    {
      id: 0,
      icon: <IoLogoGitlab />,
      text: "Machine",
      path: "/auth/machine",
    },
  ];
  return (
    <div className={`auth_side_bar ${side ? "" : "close"}`}>
      
      <button
        type="button"
        className={side ? "open" : "close"}
        onClick={() => setSide((prev) => !prev)}
      >
        <MdOutlineKeyboardDoubleArrowRight />
      </button>
      <NavBtn
        path="/auth/home"
        text="Dashboard"
        icon={<RxDashboard />}
        side={side}
      />

      {/* LMS */}
      <TabDivider title="LMS" side={side} />

      {menuData.map((data) => {
        return (
          <Fragment key={data.id}>
            <NavBtn {...data} side={side} />
          </Fragment>
        );
      })}

      {/* LABS */}

      <TabDivider title="Labs" side={side} />

      {labsData.map((data) => {
        return (
          <Fragment key={data.id}>
            <NavBtn {...data} side={side} />
          </Fragment>
        );
      })}
    </div>
  );
};
export default AuthSidebar;
