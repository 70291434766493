import "./CourseEventPopup.scss";
import Modal from "react-bootstrap/Modal";
import PrimaryBtn from "../../../../components/primaryBtn/PrimaryBtn";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FieldErrorMessage from "../../../../authPages/components/errorMessage/FieldErrorMessage";
import axios from "axios";
import { baseUrl, eventForm } from "../../../../utils/apidata";
import { useContext, useState } from "react";
import { redirectContext } from "../../../../context/RoutingContext";
import { MdClose } from "react-icons/md";

const CourseEventPopup = (props) => {
  const [loader, setLoader] = useState(false);
  const { toastSuccess, toastError } = useContext(redirectContext);
  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    hear_about: "",
    feature: [],
  };

  const tempModelData = [
 
    [
      "Firewalls (FortiGate) Checkpoint",
      "Cyber Infrastructure",
      "SIEM SOC IR By Using - Splunk & IBMQqradar ",
      "Network forensics",
      "Python programming ",
    ],
    [
      "Internal Penetration Testing",
      "External Penetration Testing",
      "Active Directory Penetration Testing",
      "Python Networking & Hacking",
    ],
    [
      "Exploit Development & detection",
      "Defensive Security Automation",
      "Privilege Escalation & Lateral Movement Emulation & Detection",
      "Purple Team Live Cickle",
    ],
    [
      "Windows Server Management & Domain Services",
      "Cloud Computing IAC (Infrastructure as Code) Terraform",
      "AWS fundamentals",
      "Network Troubleshooting",
    ],
  ];

  const validationSchema = Yup.object({
    name: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("invalid email formate!")
      .required("email is required!"),
    mobile: Yup.string().required("Field is required!"),
    hear_about: Yup.string().required("Field is required!"),
  });

  const postEventSurveForm = async (values) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${eventForm}`, { ...values });
      if (res?.data?.success) {
        toastSuccess(res?.data?.message);
        setLoader(false);
      }
    } catch (error) {
      toastError("Something went wrong");
      setLoader(false);
    }
  };

  const onSubmit = (values, { resetForm }) => {
    postEventSurveForm(values);
    resetForm();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="event_course_view"
    >
      <Modal.Body>
        <div className="modal_content_course">
          <button className="close" onClick={props.onHide}><MdClose /></button>
          <p className="title">
            Welcome to the Cyber Gain Center online courses portal! Feel free to
            select the course of your choice, Register, and we’ll get in touch
            with you soon.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="checkbox_line">
                {tempModelData[props?.openIndex]?.map((data) => {
                  return (
                    <div className="check_wrapers" key={data}>
                      <Field type="checkbox" name="feature" value={data} />
                      <label>
                        <p>{data}</p>
                      </label>
                    </div>
                  );
                })}
              </div>

              <div className="popup_field_wrap">
                <label>Name</label>
                <Field
                  type="text"
                  placeholder="Name"
                  id="name"
                  name="name"
                  autoComplete="off"
                />
                <ErrorMessage name="name" component={FieldErrorMessage} />
              </div>
              <div className="bi_grid">
                <div className="popup_field_wrap">
                  <label>Email</label>
                  <Field
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    autoComplete="off"
                  />
                  <ErrorMessage name="email" component={FieldErrorMessage} />
                </div>

                <div className="popup_field_wrap">
                  <label>Number</label>
                  <Field
                    type="number"
                    placeholder="Number"
                    id="mobile"
                    name="mobile"
                    autoComplete="off"
                  />
                  <ErrorMessage name="mobile" component={FieldErrorMessage} />
                </div>
              </div>

              <div className="popup_field_wrap">
                <label>Where did you hear about us?</label>
                <Field
                  type="text"
                  placeholder="e.g. Google"
                  id="hear_about"
                  name="hear_about"
                  autoComplete="off"
                />
                <ErrorMessage name="hear_about" component={FieldErrorMessage} />
              </div>

              <PrimaryBtn
              style={{marginLeft:"auto", marginRight:"auto", marginTop:"16px"}}
                type="submit"
                title={loader ? "Loading..." : "Submit"}
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CourseEventPopup;
