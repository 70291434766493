import "./FieldCover.scss";

const FieldCover = (props) => {
  return (
    <div className={`field_cover ${props.classList}`} data-wow-delay={props.delay} style={props.style}  >
      {props.children}
      <span className="corner a"></span>
      <span className="corner b"></span>
      <span className="corner c"></span>
      <span className="corner d"></span>
    </div>
  );
};

export default FieldCover;
