import "./MachineInAction.scss";
import { images } from "../../../../utils/images/images";
import { ImWindows } from "react-icons/im";
import { PiCirclesThreeFill } from "react-icons/pi";
import { FiFlag } from "react-icons/fi";
import { GoHash } from "react-icons/go";
import { BsFillDropletFill } from "react-icons/bs";
import { Fragment, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { HiArrowsUpDown } from "react-icons/hi2";
import { IoPlay } from "react-icons/io5";
import { SiCyberdefenders } from "react-icons/si";
import { GoSquareFill } from "react-icons/go";
import { VscDebugRestart } from "react-icons/vsc";
import { FaCircleArrowRight } from "react-icons/fa6";


const MachineInAction = () => {
  const [running, setRunning] = useState(false);
  const [process, setProcess] = useState(false);

  const startMachine = () => {
    setProcess(true);

    setTimeout(() => {
      setProcess(false);
      setRunning(true);
    }, 3000);
  };

  return (
    <div className="machin_in_action">
      <div className="left_info">
        <div className="about_machine">
          <div className="img_wrap">
            <img src={images.machineA} alt="machine" />
          </div>
          <div className="text">
            <h6>Amet</h6>
            <div className="info_line">
              <div className="info">
                <div className="circle">
                  <PiCirclesThreeFill />
                </div>
                <p>Insane</p>
              </div>
              <div className="info">
                <div className="circle">
                  <ImWindows />
                </div>
                <p>Windows</p>
              </div>
            </div>
          </div>
        </div>

        <div className="count_details">
          <div className="count_flex">
            <p>Machine Progress</p>
            <div className="info_wrap">
              <div className="in_fo">
                <p>+50</p>
                <div className="circle">
                  <FiFlag />
                </div>
              </div>

              <div className="in_fo">
                <p>+55</p>
                <div className="circle">
                  <GoHash />
                </div>
              </div>
            </div>
          </div>

          <div className="progress">
            <div className="fill">
              <div className="fill"></div>
            </div>
          </div>
          <div className="blood">
            <div className="circle">
              <BsFillDropletFill />
            </div>
            <p>Get +7 points on every first blood</p>
          </div>
        </div>
        <div className="created_by">
          <p>Created By</p>
          <div className="pill">
            <div className="round">
              <img src={images.followerB} alt="creator" />
            </div>
            <p>Lorem ipsum</p>
          </div>
        </div>
      </div>
      <div className="right_machine">
        <div className={`red_line ${process || running ? "light" : ""}`}>
          <div className="left">
            <GoDotFill />
            <p>{process ? "Spawning" : "US FREE 2"}</p>
          </div>
          <button type="button">
            <HiArrowsUpDown />
            <p>Switch VPN</p>
          </button>
        </div>

        {!running ? (
          <Fragment>
            <div className="play_btn">
              <button type="button" onClick={startMachine}>
                <div className="circle">
                  {process && <div className="process"></div>}

                  <IoPlay />
                </div>
                <div className="text">
                  <p>Spawn Machine</p>
                  <p>
                    <span>Spawn this Machine</span>
                  </p>
                </div>
              </button>
            </div>
            <div className="notice">
              <SiCyberdefenders />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur labore.
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="ip">
              <h6>10.10.11.17</h6>
              <p>MACHINE IP ADDRESS</p>
            </div>
            <div className="running_btn">
              <button
                type="button"
                className="stop"
                onClick={() => setRunning(false)}
              >
                <div className="circle">
                  <GoSquareFill />
                </div>
                <p>Stop Machine</p>
              </button>

              <button type="button" onClick={() => setRunning(false)}>
                <div className="circle">
                  <VscDebugRestart />
                </div>
                <p>Restart Machine</p>
              </button>
            </div>
            <form>
              <div className="flag_field">
                <input type="text" placeholder="Submit flag" />
                <button type="button"><FaCircleArrowRight /></button>
              </div>
            </form>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default MachineInAction;
