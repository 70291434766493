import "./MachineTabs.scss";
import CommonCard from "../../../components/commonCard/CommonCard";

const MachineTabs = ({ active, setActive }) => {
  return (
    <div className="machine_tabs_wrap">
      <CommonCard  classList={active === "a" ? "active" : ""}>
        <button
          type="button"    
          className={active === "a" ? "active" : ""}     
          onClick={() => {
            setActive("a");
          }}
        >
          Active Machines
        </button>
      </CommonCard>

      <CommonCard  classList={active === "b" ? "active" : ""}>
        <button
          type="button"
          className={active === "b" ? "active" : ""}     
          onClick={() => {
            setActive("b");
          }}
        >
          Retired Machines
        </button>
      </CommonCard>

      <CommonCard  classList={active === "c" ? "active" : ""}>
        <button
        className={active === "c" ? "active" : ""}     
          type="button"
          onClick={() => {
            setActive("c");
          }}
        >
          Machines To-Do List
        </button>
      </CommonCard>

      <CommonCard  classList={active === "d" ? "active" : ""}>
        <button
        className={active === "d" ? "active" : ""}     
          type="button"
          onClick={() => {
            setActive("d");
          }}
        >
          Lorem ipsum Amet
        </button>
      </CommonCard>
    </div>
  );
};

export default MachineTabs;
