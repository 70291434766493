import { useState } from "react";
import AuthLayout from "../../authPages/AuthLayout";
import "./Machine.scss";
import MachineTabs from "./machineTabs/MachineTabs";
import MachinGrid from "./machinsGrid/MachinGrid";
import MachineFilter from "./machinFilter/MachineFilter";
import MachineTable from "./machineTable/MachineTable";

const Machine = () => {
  // tabs
  const [active, setActive] = useState("b");

  // dropdown Status
  const [status, setStatus] = useState("Complete");
  const [sort, setSort] = useState("Release date");
  const [difficulty, setDifficulty] = useState("Easy");

  return (
    <AuthLayout isLabs={true}>
      <section className="machine">
        <div className="screen_container">
          <MachinGrid />
          <MachineTabs active={active} setActive={setActive} />
          <MachineFilter
            status={status}
            setStatus={setStatus}
            sort={sort}
            setSort={setSort}
            difficulty={difficulty}
            setDifficulty={setDifficulty}
          />
          <MachineTable />
        </div>
      </section>
    </AuthLayout>
  );
};

export default Machine;
