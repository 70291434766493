import { useState } from "react";
import AuthLayout from "../../authPages/AuthLayout";
import "./MachineDetail.scss";
import MachineDetailLanding from "./machineDetailLanding/MachineDetailLanding";
import MachineDetailDesc from "./machineDetailDesc/MachineDetailDesc";

const MachineDetail = () => {
  const [tab, setTab] = useState("a");
  return <AuthLayout isLabs={true}>
    <section className="machine_detail">
        <div className="screen_container">
            <MachineDetailLanding tab={tab} setTab={setTab} />
            <MachineDetailDesc tab={tab} />
        </div>
    </section>
  </AuthLayout>;
};

export default MachineDetail;
