import CommonCard from "../../../components/commonCard/CommonCard";
import { images } from "../../../utils/images/images";
import "./MachinGrid.scss";
import { DiLinux } from "react-icons/di";
import { ImWindows } from "react-icons/im";
import PrimaryBtn from "../../../components/primaryBtn/PrimaryBtn";
import { PiPower } from "react-icons/pi";
import MachineCard from "./machineCard/MachineCard";
import { BsHddStack } from "react-icons/bs";
import { MdOutlinePentagon } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const MachinGrid = () => {
  const navigate = useNavigate();

  return (
    <div className="machine_grid">
      <MachineCard
        machineImg={images.machineA}
        systemIcon={<DiLinux />}
        pillText="Seasonal"
        title="Headless"
        level="Easy"
        btnText="Play Machine"
        clickHandler={() => navigate("/auth/machine/headless")}
      />

      <MachineCard
        machineImg={images.machineA}
        systemIcon={<ImWindows />}
        pillText="Staff Pick"
        title="Authority"
        level="Medium"
        btnText="Play Machine"
        clickHandler={() => navigate("/auth/machine/authority")}
      />

      <div className="bi_line">
        <CommonCard>
          <div className="info_line">
            <BsHddStack />
            <div className="text">
              <p>Get Rewarded</p>
              <p className="t-i-14">Submit a Machine</p>
            </div>
          </div>
        </CommonCard>
        <CommonCard>
          <div className="info_line">
            <MdOutlinePentagon />
            <div className="text">
              <p>24H/MO PWNBOX + RETIRED CONTENT</p>
              <p className="t-i-14">Upgrade to VIP</p>
            </div>
          </div>
        </CommonCard>
      </div>
    </div>
  );
};
export default MachinGrid;
