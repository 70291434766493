import "./MachineDetailLanding.scss";
import { IoChevronBackSharp } from "react-icons/io5";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { LuPrinter } from "react-icons/lu";
import { images } from "../../../utils/images/images";
import { BsStarFill } from "react-icons/bs";
import { BsStarHalf } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";

const MachineDetailLanding = ({tab, setTab}) => {
  const navigate = useNavigate();

  return (
    <div className="machine_detail_landing">
      <div className="top_btn_line">
        <button
          type="button"
          className="back"
          onClick={() => navigate("/auth/machine")}
        >
          <IoChevronBackSharp />
        </button>
        <div className="right_wrap">
          <button type="button" className="white">
            <LuPrinter /> Active Seasion 4 Machine
          </button>
          <button type="button" className="red">
            <IoCloudOfflineOutline /> Headless is offline
          </button>
        </div>
      </div>
      <div className="machine_info_line">
        <div className="machine_info">
          <div className="img_wrap">
            <img src={images.machineA} alt="machine" />
          </div>
          <div className="text">
            <h6>Headless</h6>
            <p>Linux · Easy</p>
          </div>
        </div>
        <div className="data_info">
          <div className="info">
            <h4>20</h4>
            <p>Points</p>
          </div>
          <div className="info">
            <div className="stars">
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStarFill />
              <BsStarHalf />
            </div>
            <p>4.8 58 Reviews</p>
          </div>
          <div className="info">
            <img src={images.graph} alt="graph" />
            <p>User rated difficulties</p>
          </div>
        </div>
      </div>
      <div className="tab_line">
        <div className="tab_wrap">
            <button type="button" className={tab === "a" ? "active" : ""} onClick={() => setTab("a")}>Play Machine</button> 
            <button type="button" className={tab === "b" ? "active" : ""} onClick={() => setTab("b")} disabled>Machine Info</button>
            <button type="button" className={tab === "c" ? "active" : ""} onClick={() => setTab("c")}>Walkthroughs</button>
            <button type="button" className={tab === "d" ? "active" : ""} onClick={() => setTab("d")} disabled>Reviews</button>
            <button type="button" className={tab === "e" ? "active" : ""} onClick={() => setTab("e")}>Activity</button>
            <button type="button" className={tab === "f" ? "active" : ""} onClick={() => setTab("f")} disabled>Changelog</button>
        </div>
        <button type="button" className="like"><FaHeart /></button>
      </div>
    </div>
  );
};

export default MachineDetailLanding;
